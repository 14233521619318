const BackSoon = () => {
  return (
    <div className="bg-coca-cola-red text-white font-bold normal-case h-full flex flex-col items-center justify-center text-center">
      <p className="text-4xl">Ο διαγωνισμός έληξε.</p>
      <p className="text-4xl">Θα είμαστε σύντομα κοντά σας!</p>
    </div>
  );
};

export default BackSoon;
