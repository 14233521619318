import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { useState } from "react";
import PageLayout from "./layouts/PageLayout";
import AdminLogin from "./pages/AdminLogin";
import AdminAuthGuard from "./guards/AdminAuthGuard";
import AdminHome from "./pages/AdminHome";
import BackSoon from "./pages/BackSoon";

function App() {
  const [isMiniMarket, setIsMiniMarket] = useState(false);

  return (
    <Routes>
      <Route path="/" element={<PageLayout isMiniMarket={isMiniMarket} />}>
        <Route index={true} element={<BackSoon />} />
      </Route>
      <Route path="ch5">
        <Route path="login" element={<AdminLogin />} />
      </Route>
      <Route path="ch5" element={<AdminHome />}>
        <Route index={true} element={<AdminAuthGuard />} />
      </Route>
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}

export default App;
